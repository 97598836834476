import React from 'react';
import useHasGroup from './hooks/useHasGroup';
import { getFeatureByName } from '@settings/features/features/utils/featureSettings';
import {
  WITH_DOCTOR_STAGE,
  TRANSITION_CONTROL_ENCOUNTER,
} from 'src/common/constants/constants';

type Props = {
  group: string;
  ticketStage: string;
  isEmergency: boolean;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
};

// This component is meant to be used to restrict doctor assessment inside an encounter for transition control
const RestrictDoctorAssessment: React.FunctionComponent<Props> = ({
  group,
  ticketStage,
  isEmergency,
  fallback,
  loadingComponent,
  children,
}) => {
  const [loading, hasGroup] = useHasGroup(group);
  const feature = getFeatureByName(TRANSITION_CONTROL_ENCOUNTER);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  if (!feature?.isEnabled) {
    return <>{children}</>;
  }

  if (
    !hasGroup ||
    !ticketStage ||
    isEmergency ||
    (hasGroup && ticketStage.toLocaleLowerCase() === WITH_DOCTOR_STAGE)
  ) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default RestrictDoctorAssessment;
