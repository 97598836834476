import { store } from 'src/common/store/index';

export function getFeatureByName(featureName: string) {
  const features = store.getState().features.features;
  return (
    features.filter(
      (feature) =>
        feature.featureName.toLowerCase() === featureName.toLowerCase(),
    )[0] || null
  );
}
